import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import Mint from "../Mint/Mint";
import Whitelist from "../Whitelist/Whitelist";
import InvitationCode from "../InvitationCode/InvitationCode";
import useQuery from "../../../hooks/useQuery";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import AccessModal from "../AccessModal/AccessModal";
import { Enviroments } from "../../../constants/constants";
import { withRouter } from "react-router-dom";
import "./BasicInfo.scss";
import { PencilFill } from "react-bootstrap-icons";
import EditCollectionModal from "../EditCollectionModal/EditCollectionModal";
import frame from "../../../assets/images/Frame.svg";
import { useSelector } from "react-redux";
import { length, numericality, required } from "redux-form-validators";
import ConnectWallet from "../ConnectWallet/ConnectWallet";

function BasicInfo({
  collectionDetails,
  getCustomizedColor,
  contractFetch,
  walletAddress,
  connectWallet,
  match,
  setCollectionDetails,
  nfts,
  showUpdateDatesModal,
  setShowUpdateDatesModal,
  setWalletAddress,
  banners,
}) {
  console.log({ walletAddress });
  console.log({ banners });
  const { PUBLISH_PARAMS } = Enviroments;
  const [disableMinting, setDisableMinting] = useState(true);
  const [invitation, setInvitation] = useState({
    numberOfWalletJoined: 0,
    maxNumberOfWallet: 0,
    inviteId: null,
    type: "",
  });
  const query = useQuery();

  const inviteCode = query.get("code");
  const externalLink = query.get("collection");

  const dispatch = useDispatch();

  const [publishParams, setPublishParams] = useState(PUBLISH_PARAMS);

  const [showAccessModal, setShowAccessModal] = useState(false);
  const [disableMessage, setDisableMessage] = useState("");
  const [showEditCollectionModal, setShowEditCollectionModal] = useState(false);
  const isLoggedIn = useSelector((state) => state.persist.isLoggedIn);
  const [modifier, setModifier] = useState({
    name: "",
    text: "",
    validation: [],
  }); // to edit the property of collectiondetails in updatemodal

  const isCreator = useMemo(() => {
    return (
      !!walletAddress &&
      walletAddress === collectionDetails.walletAddress.toLowerCase()
    );
  });

  const maxMintPossible = () => {
    let obj = {
      message: "",
      maxNumber: 0,
    };
    if (collectionDetails.supply < 100) {
      obj.maxNumber = collectionDetails.supply;
      obj.message = `can't be more than supply`;
    } else if (collectionDetails.supply == 100) {
      obj.maxNumber = 100;
      obj.message = `must be between 1 to ${obj.maxNumber} and integer only`;
    } else {
      obj.maxNumber = 100;
      obj.message = `must be between 1 to ${obj.maxNumber} and integer only`;
    }
    return obj;
  };

  const { MINTING_TYPES } = Enviroments;

  const isPrivate = useMemo(() => {
    if (match.path.indexOf("auth") != -1 && isLoggedIn) {
      return true;
    }
    return false;
  }, [match, isLoggedIn]);

  const getInvitationDetailByInviteCode = async (inviteCode) => {
    try {
      const res = await dispatch(
        CollectionActions.getInvitationDetailByInviteCode(inviteCode)
      );
      if (res.data.data) {
        const { maxNumberOfWallet, numberOfWalletJoined, _id, type } =
          res.data.data;
        setInvitation({
          maxNumberOfWallet,
          numberOfWalletJoined,
          type,
          inviteId: _id,
        });
      } else {
        setShowAccessModal(true);
        setDisableMessage("Invitation code doesn't exist");
        setInvitation({
          inviteId: -1,
        });
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setShowUpdateDatesModal(false);
  };

  const openEditCollectionModal = (obj) => {
    if (!!obj) setModifier(obj);
    setShowEditCollectionModal(true);
  };

  const debugEverything = () => {
    let obj = {
      isCreator,
      invitation,
      walletAddress,
      mintingType,
    };
    console.table(obj);
  };

  useEffect(() => {
    if (inviteCode) getInvitationDetailByInviteCode(inviteCode);
  }, [inviteCode]);

  const mintingType = useMemo(() => {
    let type = collectionDetails.isPrivateMintingAllowed;
    return type == true ? MINTING_TYPES.private : MINTING_TYPES.public;
  }, [collectionDetails]);



  return (
    <div className="collection-basic-info">
      <section>
        <div className="text-center mb-4">
          <Image
            className={`nftImage ${
              banners.banner1.isShow == true ? "withbanner" : ""
            }`}
            src={collectionDetails.logo}
          />
          <div>
            <Button className="frame">
              <Image className="imgSec" src={frame} /> <span /> MINTING TECH.
              provided by SPOZZ.Club
            </Button>
          </div>
        </div>
      </section>
      <section>
        <div className="Right ">
          {collectionDetails && Object.keys(collectionDetails).length > 0 && (
            <>
              <h5
                style={getCustomizedColor("heading")}
                className="labeltext  mb-4"
              >
                Name:{" "}
                <p
                  className="mb-0  ms-2 inputSec"
                  style={getCustomizedColor("inputField")}
                >
                  {" "}
                  {collectionDetails.name}
                </p>
              </h5>
              {/* <p style={getCustomizedColor("text")}>
                {collectionDetails.description}
              </p>  */}
              {collectionDetails.isLive && (
                <>
                  <h5
                    style={getCustomizedColor("heading")}
                    className="labeltext  mb-4"
                  >
                    Contract Address: {/* {debugContractAddress()} */}
                    {contractFetch.isContractFound == true ? (
                      <p
                        className="mb-0  ms-2 inputSec"
                        style={getCustomizedColor("inputField")}
                      >
                        {contractFetch.contract.contractAddress.toLowerCase()}{" "}
                      </p>
                    ) : (
                      <div class="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </h5>
                  <CountdownTimer
                    collectionDetails={collectionDetails}
                    getCustomizedColor={getCustomizedColor}
                    setDisableMinting={setDisableMinting}
                  />
                  <h5
                    style={getCustomizedColor("heading")}
                    className="labeltext  mb-4"
                  >
                    Wallet :
                    <p
                      className="mb-0 ms-2 inputSec"
                      style={getCustomizedColor("inputField")}
                    >
                      {walletAddress}
                    </p>
                    {walletAddress && !isPrivate && (
                      <ConnectWallet className="mx-2" isChange="true" collectionDetails={collectionDetails}/>
                    )}
                  </h5>

                  <h5
                    style={getCustomizedColor("heading")}
                    className="labeltext mb-4"
                  >
                    Price per Unit :
                    <p
                      className="mb-0 ms-2 inputSec"
                      style={getCustomizedColor("inputField")}
                    >
                      {collectionDetails.price}{" "}
                      {collectionDetails.blockChain.blockChain}
                      {isPrivate == true && (
                        <PencilFill
                          className="edit-collection"
                          onClick={() =>
                            openEditCollectionModal({
                              name: "price",
                              text: "Price per Unit :",
                              validation: [
                                required(),
                                numericality({ float: true, ">": 0 }),
                                length({
                                  max: 5,
                                  msg: "too many digits only 3 allowed",
                                }),
                              ],
                            })
                          }
                        />
                      )}
                    </p>
                  </h5>
                  <h5
                    style={getCustomizedColor("heading")}
                    className="labeltext  mb-4"
                  >
                    Max. Mint per Wallet :
                    <p
                      className="mb-0 ms-2 inputSec"
                      style={getCustomizedColor("inputField")}
                    >
                      {collectionDetails.mintPerWallet}
                      {isPrivate == true && (
                        <PencilFill
                          className="edit-collection"
                          onClick={() =>
                            openEditCollectionModal({
                              name: "mintPerWallet",
                              text: "Max. Mint per Wallet :",
                              validation: [
                                required(),
                                numericality({
                                  integer: true,
                                  ">=": 1,
                                  "<=": maxMintPossible().maxNumber,
                                  msg: maxMintPossible().message,
                                }),
                              ],
                            })
                          }
                        />
                      )}
                    </p>
                  </h5>
                  <h5
                    style={getCustomizedColor("heading")}
                    className="labeltext  mb-4"
                  >
                    Total Supply :
                    <p
                      className="mb-0 ms-2 inputSec"
                      style={getCustomizedColor("inputField")}
                    >
                      {collectionDetails.supply}
                    </p>
                  </h5>
                </>
              )}

              {collectionDetails.isLive && (
                <>
                  {debugEverything()}
                  {isCreator == false && isPrivate == false && (
                    <>
                      {(invitation.inviteId && invitation.inviteId != -1) ||
                      !inviteCode ? (
                        <>
                          <Whitelist
                            collectionDetails={collectionDetails}
                            getCustomizedColor={getCustomizedColor}
                            walletAddress={walletAddress}
                            connectWallet={connectWallet}
                            invitation={invitation}
                          />

                          <Mint
                            walletAddress={walletAddress}
                            collectionDetails={collectionDetails}
                            getCustomizedColor={getCustomizedColor}
                            connectWallet={connectWallet}
                            disableMinting={disableMinting}
                            contractFetch={contractFetch}
                            nfts={nfts}
                            isPrivate={isPrivate}
                            mintingType={mintingType}
                          />
                        </>
                      ) : (
                        <>
                          <AccessModal
                            show={showAccessModal}
                            message={disableMessage}
                          />
                        </>
                      )}
                    </>
                  )}
                  {isPrivate == true && (
                    <>
                      {mintingType == MINTING_TYPES.public ? (
                        <InvitationCode
                          collectionDetails={collectionDetails}
                          getCustomizedColor={getCustomizedColor}
                        />
                      ) : (
                        <>
                          <InvitationCode
                            collectionDetails={collectionDetails}
                            getCustomizedColor={getCustomizedColor}
                          />
                          <Mint
                            walletAddress={walletAddress}
                            collectionDetails={collectionDetails}
                            getCustomizedColor={getCustomizedColor}
                            connectWallet={connectWallet}
                            disableMinting={false}
                            contractFetch={contractFetch}
                            mintingType={mintingType}
                            nfts={nfts}
                            isPrivate={isPrivate}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
      {showEditCollectionModal && (
        <EditCollectionModal
          show={showEditCollectionModal}
          setShow={setShowEditCollectionModal}
          collectionDetails={collectionDetails}
          setCollectionDetails={setCollectionDetails}
          modifier={modifier}
        />
      )}
    </div>
  );
}

export default withRouter(BasicInfo);
