import { Enviroments } from "../constants/constants";
import { toast } from "react-toastify";
import WalletManagerService from "./WalletManager.service";

class Wallet {

  static async connectWallet(wallet, redux, infuraId) {
    console.log(redux); 
    let obj = null;
    if(!wallet){
      return {accounts:"",provider:""};
    }
    if (wallet === Enviroments.WALLET_TYPES.walletConnect) {
      obj = await WalletManagerService.enableWallet(wallet, redux, undefined, infuraId);
    } else if (wallet === Enviroments.WALLET_TYPES.metamask) {
      obj = await WalletManagerService.enableWallet(wallet);
    }
    let {accounts, provider} = obj;

    if (accounts && accounts.length === 0)
      return toast.error("Failed to connect wallet.");
    accounts = accounts[0];
    return {accounts,provider};
  }

  static getProvider(name) {
    let provider;
    const { WALLET_PROVIDERS } = Enviroments;
    if (typeof window.ethereum !== "undefined") {
      provider = window.ethereum;
      if (window.ethereum.providers) {
        provider = window.ethereum.providers.find(
          (x) => x[WALLET_PROVIDERS[name]] == true
        );
      }
    } else {
      throw new Error("Please install " + name);
    }
    return provider;
  }

  static getAllProviders() {
    let provider;
    if (typeof window.ethereum !== "undefined") {
      provider = window.ethereum;
      if (window.ethereum.providers) {
        provider = window.ethereum.providers
      }
    }
    return provider
  }
}

export default Wallet;
