import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Draft.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  CollectionActions,
  getContractByBlockChain,
} from "../../redux/actions/collection.action";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { Enviroments } from "../../constants/constants";
import ReleaseDateConfirmationModal from "../common/ReleaseDateConfirmationModal/ReleaseDateConfirmationModal";
import { toasts as toast } from "../common/Toast/Toast";
import { Dropdown } from "react-bootstrap";
import AttributesModal from "../common/AttributesModal/AttributesModal";
import DeleteConfirmationModal from "../common/DeleteConfirmationModal/DeleteConfirmationModal";
import Web3 from "web3";
import WalletManagerService from "../../services/WalletManager.service";

function Draft({ history, setKey }) {
  const { PUBLISH_PARAMS } = Enviroments;
  const [collections, setCollections] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const [showReleaseDateModal, setShowReleaseDateModal] = useState(false);
  const [draftReleaseDate, setDraftReleaseDate] = useState(null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [showAttributeModal, setShowAttributeModal] = useState(false);
  const [publishParams, setPublishParams] = useState(PUBLISH_PARAMS);

  const [page, setPage] = useState(0);
  const limit = 3;
  const numberOfPages = Math.ceil(totalCount / limit);
  const {
    COLLECTION_TYPES: { DRAFT },
    COUNTDOWN_TYPES,
  } = Enviroments;
  const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false);

  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e - 1,
      filters: {},
      type: DRAFT,
    };
    // getCollections(obj);
  };

  const getCollections = async (data) => {
    const { getCollections } = CollectionActions;
    try {
      const res = await dispatch(getCollections(data));
      setCollections(res.data.data);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getCollections({
      page: page == 0 ? 0 : page - 1,
      limit: limit,
      filters: {},
      type: DRAFT,
    });
  }, [page]);

  const handleResponse = (res) => {
    if (res.isWhiteListedEnable) {
      setDraftReleaseDate(res.releaseDate);
    } else {
      setDraftReleaseDate(res.mainSaleStartsIn);
    }
    setPublishParams(res);
  };

  const collectionGoLive = async (obj) => {
    try {
      let data = {
        _id: obj._id,
        ...publishParams,
        contractName:obj.contractName
      };
      console.log(data)
      const { collectionGoLive } = CollectionActions;
      const provider = WalletManagerService.initialize();
      const web3 = new Web3(provider)
      let response = await dispatch(getContractByBlockChain(obj, data, web3));

      // let res = await dispatch(collectionGoLive(data));
      toast.success("Collection is live now..!!");
      setDraftReleaseDate(null);
      setTimeout(() => {
        setKey("collections");
      }, 2000);
    } catch (error) {
      setDraftReleaseDate(null);
      // console.log(error);
      if (error && error.code == -32602) {
        error.message = "Only a creator can publish the collection";
      }
      toast.error(error.message);
    }
  };

  const handleClose = () => {
    setShowReleaseDateModal(false);
    setShowDeleteDraftModal(false);
  };

  const selectDraft = (id) => {
    let draftSelected = collections.list.find((draft) => draft._id === id);
    setSelectedDraft(draftSelected);
    if (draftSelected) {
      setShowReleaseDateModal(true);
    }
  };

  const openAttributeModal = () => {
    setShowAttributeModal(true);
  };

  const confirmDeleteDraft = (choice) => {
    if (choice == false) setShowDeleteDraftModal(false);

    deleteDraft(selectedDraft);
  };

  const deleteDraft = async (id) => {
    try {
      let res = await dispatch(CollectionActions.deleteDraft(id));
      setShowDeleteDraftModal(false);
      getCollections({ page: 0, limit: 10, filters: {}, type: DRAFT });
    } catch (error) {
      setShowDeleteDraftModal(false);
      // console.log(error);
    }
  };

  const uploadNft = async (row) => {
    await dispatch(CollectionActions.allowNFTCreation(true))

    history.push({
      pathname: `/auth/collection/UploadNft/${row._id}`,
      state: {
        externalLink:row.externalLink
      }
    })
  }

  const openDeleteDraftModal = (id) => {
    setSelectedDraft(id);
    setShowDeleteDraftModal(true);
  };

  useEffect(() => {
    if (!!draftReleaseDate) {
      collectionGoLive(selectedDraft);
    }
  }, [draftReleaseDate]);

  return (
    <Container className="ContMain">
      <Row className="banner_row ">
        <Col lg={9}>
          <div className="collection_col">
            <h2 className="main-heading ">Drafts </h2>
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        {collections.totalCounts > 0 ? (
          <>
            {collections.list.map((row, i) => (
              <>
                <Col sm={6} lg={3} key={i}>
                  <div className="coll-new mb-3">
                    <div className="imageSec">
                      <Image fluid src={row.logo} alt="name" />
                    </div>
                    <h5 className="mt-2">{row.name}</h5>
                  </div>
                </Col>
                <Col>
                  <div className="publishBtn">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => selectDraft(row._id)}>
                          Publish
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => openAttributeModal(row._id)}
                        >
                          Add Attributes
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            history.push(
                              `/auth/collection/details/${row.externalLink}`
                            )
                          }
                        >
                          Customize
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                           uploadNft(row)
                          }
                        >
                          Upload Nft
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => openDeleteDraftModal(row._id)}
                        >
                          Delete Draft
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <AttributesModal
                  show={showAttributeModal}
                  setShow={setShowAttributeModal}
                  collectionId={row._id}
                />
              </>
            ))}
          </>
        ) : (
          <p>No Drafts Found</p>
        )}
      </Row>
      {totalCount > limit && (
        <Row>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </Row>
      )}
      {!!selectedDraft && (
        <ReleaseDateConfirmationModal
          show={showReleaseDateModal}
          handleClose={handleClose}
          setLiveParams={handleResponse}
          supply={selectedDraft.supply}
          setShowReleaseDateModal={setShowReleaseDateModal}
        />
      )}

      <DeleteConfirmationModal
        show={showDeleteDraftModal}
        handleClose={handleClose}
        confirmDelete={confirmDeleteDraft}
        item={"Draft"}
        message={"Are you sure you want to delete this draft?"}
      />
    </Container>
  );
}

export default withRouter(Draft);
