import types from "../types";

const initialState = {
  walletAddress: "",
  isLoggedIn: false,
  jwtToken: "",
  user: null,
  cryptoToken:"reyYnduXqJzfrJmXrum-"
};

const persist = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.persist.SAVE_WALLET_ADDRESS:
      return { ...state, walletAddress: payload };
    case types.persist.SAVE_IS_LOGGED_IN:
      return { ...state, isLoggedIn: true };
    case types.persist.SAVE_JWT_TOKEN: {
 
      return { ...state, jwtToken: payload };
    }
    case types.persist.SAVE_USER:
      return { ...state,  user: payload };
    case types.persist.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default persist;
