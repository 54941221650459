import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { CollectionActions } from "../../../redux/actions/collection.action";
import WalletManagerService from "../../../services/WalletManager.service";
import ReleaseDateConfirmationModal from "../ReleaseDateConfirmationModal/ReleaseDateConfirmationModal";
import { toasts as toast } from "../Toast/Toast";
import Web3 from "web3";

function PublishSection({
  collectionDetails,
  getCustomizedColor,
  history,
  showUpdateDatesModal,
  setShowUpdateDatesModal,
  handleDatesUpdate,
}) {
  console.log({ showUpdateDatesModal });
  const [showReleaseDateModal, setShowReleaseDateModal] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowReleaseDateModal(false);
    setShowUpdateDatesModal(false);
  };

  const handleResponse = (res) => {
    collectionGoLive({ ...collectionDetails, ...res });
  };

  const collectionGoLive = async (obj) => {
    try {
      let data = {
        _id: collectionDetails._id,
        ...obj,
      };
      console.log({apidata:data})
      const provider = WalletManagerService.initialize();
      const web3 = new Web3(provider)
      const { collectionGoLive, getContractByBlockChain } = CollectionActions;
      let response = await dispatch(getContractByBlockChain(obj, data, web3));
      toast.success("Collection is live now..!!");
      
    } catch (error) {
      // console.log(error);
      if (error.code == -32602) {
        error.message = "Only a creator can publish the collection";
      }
      toast.error(error.message);
    } finally {
      handleClose();
    }
  };

  return (
    <section className="five">
      <Container>
        <div class=" mb-4 " />
        <h4 style={getCustomizedColor("heading")} className="title mb-0">
          SPOZZ_SPACE
        </h4>
        {/* <p className="mb-3">SPOZZIES</p> */}
        <div className="mt-2">
        {!collectionDetails.isLive && (
          
            <Button
              variant="primary"
              style={getCustomizedColor ? getCustomizedColor("button") : null}
              className="cmnBtn mb-4"
              onClick={setShowReleaseDateModal}
            >
              PUBLISH
            </Button>
        )}
          <Button
            variant="primary"
            style={getCustomizedColor ? getCustomizedColor("button") : null}
            className="cmnBtn mb-4 ms-3"
            onClick={() => history.push("/auth/collection/allCollection")}
          >
            Back to Collections
          </Button>
          </div>
      </Container>
      {!!collectionDetails && (
        <ReleaseDateConfirmationModal
          show={showUpdateDatesModal || showReleaseDateModal}
          handleClose={handleClose}
          setLiveParams={handleResponse}
          date={collectionDetails.releaseDate}
          supply={collectionDetails.supply}
          collectionDetails={collectionDetails}
          setShowReleaseDateModal={setShowReleaseDateModal}
          setShowUpdateDatesModal={setShowUpdateDatesModal}
          showUpdateDatesModal={showUpdateDatesModal}
          handleDatesUpdate={handleDatesUpdate}
        />
      )}
    </section>
  );
}

export default withRouter(PublishSection);
